import {injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'

export const timeoutWarningThresholdSeconds = 60

@injectable()
@Service
export default class TokenTimeoutService {

  private static warningTimeout: NodeJS.Timeout = setTimeout(() => {null}, 0)
  private static timeoutFunctions: Array<() => void> = []
  private static displayModal = false

  updateTimeout(timestamp: string) {
    const newValue = new Date(timestamp).getTime()
    const timeUntilWarning = newValue - Date.now() - (timeoutWarningThresholdSeconds * 1000)

    TokenTimeoutService.hideModal()
    clearTimeout(TokenTimeoutService.warningTimeout)
    TokenTimeoutService.warningTimeout = setTimeout(
      () => {
        TokenTimeoutService.showModal()
        TokenTimeoutService.timeoutFunctions.forEach(fn => fn())
      },
      timeUntilWarning,
    )
  }

  onTimeout(fn: () => void) {
    TokenTimeoutService.timeoutFunctions.push(fn)
  }

  removeTimeout(fn: () => void) {
    TokenTimeoutService.timeoutFunctions = TokenTimeoutService.timeoutFunctions.filter(it => it !== fn)
  }

  static showModal() {
    TokenTimeoutService.displayModal = true
  }

  static hideModal() {
    TokenTimeoutService.displayModal = false
  }

  static getModalVisibility() {
    return TokenTimeoutService.displayModal
  }
}
